var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ui-settings" },
    [
      _c("app-navigation", {
        attrs: {
          title: _vm.$t("menu-items.menu-items"),
          actionButtonIcon: "fa fa-upload",
          actionButtonDesc: _vm.$t("menu-items.save-menu-items")
        },
        on: { action: _vm.onSaveMenuItems }
      }),
      _c(
        "el-row",
        {
          staticClass: "header",
          staticStyle: { "margin-bottom": "10px", "font-weight": "bold" }
        },
        [
          _c("el-col", { staticStyle: { width: "10%" } }, [
            _vm._v(
              "\n        " + _vm._s(_vm.$t("menu-items.enable")) + "\n    "
            )
          ]),
          _c("el-col", { staticStyle: { width: "10%" } }, [
            _vm._v("\n        " + _vm._s(_vm.$t("menu-items.icon")) + "\n    ")
          ]),
          _c("el-col", { staticStyle: { width: "10%" } }, [
            _vm._v("\n        " + _vm._s(_vm.$t("menu-items.title")) + "\n    ")
          ]),
          _c(
            "el-col",
            {
              staticStyle: {
                width: "70%",
                "text-align": "right",
                "padding-right": "20px"
              }
            },
            [
              _vm._v(
                "\n        " + _vm._s(_vm.$t("menu-items.actions")) + "\n    "
              )
            ]
          )
        ],
        1
      ),
      _c(
        "Container",
        {
          attrs: { "drag-handle-selector": ".column-drag-handle" },
          on: { drop: _vm.onDrop }
        },
        _vm._l(_vm.items, function(item) {
          return _c("Draggable", { key: item.id }, [
            _c(
              "div",
              { staticClass: "draggable-item" },
              [
                _c(
                  "el-col",
                  { staticStyle: { width: "10%" } },
                  [
                    _c("el-checkbox", {
                      staticStyle: { "margin-left": "10px" },
                      model: {
                        value: item.enable,
                        callback: function($$v) {
                          _vm.$set(item, "enable", $$v)
                        },
                        expression: "item.enable"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-col",
                  {
                    staticStyle: {
                      width: "10%",
                      display: "flex",
                      "align-items": "center",
                      "margin-top": "10px",
                      "margin-bottom": "10px"
                    }
                  },
                  [
                    _c("img", {
                      staticClass: "icon-contact",
                      attrs: { src: "/assets/icons/" + item.icon + ".png" },
                      on: { error: _vm.onImageError }
                    })
                  ]
                ),
                _c("el-col", { staticStyle: { width: "10%" } }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$t("menu-items.types." + item.id)) +
                      "\n            "
                  )
                ]),
                _c("el-col", { staticStyle: { width: "70%" } }, [
                  item.enable
                    ? _c(
                        "span",
                        {
                          staticClass: "column-drag-handle",
                          staticStyle: { float: "right", padding: "0 20px" }
                        },
                        [_vm._v("☰")]
                      )
                    : _vm._e()
                ])
              ],
              1
            )
          ])
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }