<template>
    <div class="ui-settings">
        <app-navigation :title="$t('menu-items.menu-items')" actionButtonIcon="fa fa-upload" :actionButtonDesc="$t('menu-items.save-menu-items')" @action="onSaveMenuItems"></app-navigation>

        <el-row class="header" style="margin-bottom: 10px; font-weight: bold">
        <el-col style="width:10%">
            {{ $t('menu-items.enable') }}
        </el-col>
        <el-col style="width:10%">
            {{ $t('menu-items.icon') }}
        </el-col>
        <el-col style="width:10%">
            {{ $t('menu-items.title') }}
        </el-col>
        <el-col style="width:70%;text-align:right;padding-right:20px">
            {{ $t('menu-items.actions') }}
        </el-col>
        </el-row>

        <Container @drop="onDrop" drag-handle-selector=".column-drag-handle">
            <Draggable v-for="item in items" :key="item.id">
                <div class="draggable-item">
                <el-col style="width:10%">
                    <el-checkbox v-model="item.enable" style="margin-left: 10px" />
                </el-col>
                <el-col style="width:10%; display: flex; align-items: center; margin-top: 10px; margin-bottom: 10px">
                    <img @error="onImageError" :src="'/assets/icons/' + item.icon + '.png'" class="icon-contact">
                </el-col>
                <el-col style="width:10%">
                    {{$t('menu-items.types.'+ item.id)}}
                </el-col>
                <el-col style="width:70%">
                    <span v-if="item.enable" class="column-drag-handle" style="float:right; padding:0 20px;">&#x2630;</span>
                </el-col>
                </div>
            </Draggable>
        </Container>
    </div>
</template>


<script>

  import {Container, Draggable} from "vue-smooth-dnd"
  import Navigation from '@/components/Navigation.vue';
  import { initializeWebSocket } from "@/utils/socket-utils"

  export default {
    name: "MenuItems",
    components: {
      Container, Draggable, appNavigation: Navigation
    },
    data: function () {
      return {
        items: []
      }
    },

    computed: {
    isConnected() {
      return this.$store.getters.isConnected;
    },
  },

  watch: {
    isConnected(value) {
      if (value) {
        this.fetchData();
      }
    }
  },

    methods: {
        onImageError: function(e){
            e.target.src = "/assets/icons/ic_home1.png";
        },

        onSaveMenuItems(){
        this.$store.dispatch("editMenuItems", this.items).then(response => {
          this.loading = false
          this.$message({
            type: "success",
            message: this.$t(response.message)
          })
        }).catch(error => {
          this.loading = false
          this.$message({
            type: "error",
            message: this.$t(error)
          })
        })
        },

        onDrop (dropResult) {
            this.items = this.applyDrag(this.items, dropResult)
            console.log(this.items)
        },

        applyDrag (arr, dragResult) {
            const { removedIndex, addedIndex, payload } = dragResult
            if (removedIndex === null && addedIndex === null) return arr

            const result = [...arr]
            let itemToAdd = payload

            if (removedIndex !== null) {
            itemToAdd = result.splice(removedIndex, 1)[0]
            }

            if (addedIndex !== null) {
            result.splice(addedIndex, 0, itemToAdd)
            }

            return result.map((item, index) => {
            return {...item, order: index}
            })
      },

      async fetchData() {
      try {
        await this.$store.dispatch("getMenuItems");
        this.items = [...this.$store.getters.menuItems]
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    }
    },

    created(){
      if (!this.isConnected) {
      initializeWebSocket()
    }
    this.fetchData()
    }
  }
</script>

<style scoped>
    @import "../../style/ui-settings.css";
  .column-drag-handle {
    cursor: move;
    padding: 5px;
  }

  .icon-contact {
    filter: brightness(0.45);
    width: 30px;
    height: 30px;
  }
</style>